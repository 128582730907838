import React, { useState } from 'react'
import cookies from '../helpers/cookies'
import { poolData } from '../config'
const  AmazonCognitoIdentity  = require('amazon-cognito-identity-js')
const CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool



export default () => {
    const [username, setUsername] = useState("matt@mattjalvarez.com")
    const [ password, setPassword ] = useState("")
    const [ error, setError ] = useState(false)

    return  <form onSubmit={auth.bind(this, username, password)} className="form-signin">
                <h1 className="h3 mb-3 font-weight-normal">Ignei</h1>
                <h2 className="h4 mb-3 font-weight-normal">Please sign in</h2>
                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                <input type="text" placeholder="E-Mail" className="form-control" onChange={(e) => setUsername(e.target.value)} />
                <br/>

                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <input type="password" placeholder="Password" className="form-control" id="inputPassword" onChange={ e => setPassword(e.target.value)}/>
                <button className="btn btn-lg btn-primary btn-block"  type="button" onClick={auth.bind(this, username, password)}>Log In</button>
                <p className="mt-5 mb-3 text-muted">Ignei LLC</p>
            </form>
}


const auth = (username, password) => {
    var authenticationData = {
        Username : username,
        Password : password,
    }
    console.log("data", JSON.stringify(authenticationData, null, 4))
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    console.log("Details", JSON.stringify(authenticationDetails, null, 4))
    var userData = {
        Username : username,
        Pool : userPool
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
  
    console.log("usr", JSON.stringify(Object.keys(cognitoUser.storage), null, 4))
  
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken()
          console.log("LOGGED IN", {accessToken})
          localStorage.setItem("ignei-token", JSON.stringify(result, null, 4))
          var myDate = new Date()
          myDate.setMonth(myDate.getMonth() + 12)
          cookies.setItem('ignei-token', accessToken, myDate , '/', '.ignei.com')
          alert("Successfully logged in, redirecting to dactyl")
          window.location = "https://dactyl.ignei.com"
           
        },
  
        onFailure: function(err) {
            alert(err.message || JSON.stringify(err))
        }
  
    })
  
  }
  
  